import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { InformationPageQuery } from "../../graphql/types"
import { PageProps } from "@src-types/page"
import { Page } from "@components/page/page"
import { defaultMetadata } from "@core/constants"
import { IndexHeader } from "@components/index-header/index-header"
import styles from "@features/information/style/information-page.module.scss"

export const InformationPageTemplate: FunctionComponent<
  PageProps<InformationPageQuery>
> = ({ data, ...props }) => {
  const infoData = data?.contentfulInformationPage
  const { contentBody, contentTitle, contentSubtitle, metadata } =
    infoData || {}

  const body = contentBody?.childMarkdownRemark?.html || ""
  const title = contentTitle || ""

  const subtitle = contentSubtitle?.childMarkdownRemark?.html || ""

  const subtitleElement = (
    <span dangerouslySetInnerHTML={{ __html: subtitle }} />
  )

  const metadataOverrides = {
    title: metadata?.title || defaultMetadata.title,
    description: metadata?.description || defaultMetadata.description,
    openGraphImage:
      metadata?.image?.fixed?.src || defaultMetadata.openGraphImage
  }

  return (
    <Page
      {...props}
      pageOptions={{
        headerVariant: "cream"
      }}
      metadata={{
        ...defaultMetadata,
        ...metadataOverrides
      }}
    >
      <IndexHeader header={{ title, subtitle: subtitleElement }} />
      <div className={styles.legalBody}>
        <div className={styles.content}>
          <span dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    </Page>
  )
}

export const query = graphql`
  query InformationPage($slug: String) {
    contentfulInformationPage(slug: { eq: $slug }) {
      contentTitle
      contentSubtitle {
        childMarkdownRemark {
          html
        }
      }
      contentBody {
        childMarkdownRemark {
          html
        }
      }
      metadata {
        ...Metadata
      }
    }
  }
`

export default InformationPageTemplate
